import bugsnag from '@bugsnag/js'

// NOTE: テスト実行時の notifyReleaseStages 警告が邪魔だからログ無効化
const options = process.env.RAILS_ENV === 'test' ? { logger: null } : {}

const IGNORES = {
  TypeError: ['Illegal invocation']
}

function isIgnorable (report) {
  const messages = IGNORES[report.errorClass] || []
  return messages.indexOf(report.errorMessage) !== -1
}

window.bugsnagClient = bugsnag({
  apiKey: '518d6b2694136ba82b24389eb33f1009',
  releaseStage: process.env.RAILS_ENV,
  notifyReleaseStages: ['production', 'staging'],
  beforeSend: function (report) {
    const { user } = window.APP.rails
    if (user) {
      const { id, email } = user
      report.user = { id, email }
    }

    if (isIgnorable(report)) {
      report.ignore()
    }
  },
  ...options
})
